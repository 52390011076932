.button-login {
    width: 94px !important;
    height: 42px !important;
    background-color: #5AB6DF !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin-top: 2% !important;
}

.button-login:hover {
    background-color: #54afd6 !important;
}

.link-login {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 28px !important;
    color: #442F91 !important;
    margin-top: 8% !important;
    text-decoration: none !important;
}
.subscriptionformField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.subscriptionformField a {
    display: contents;
}
.registration-body{
    background-color: rgba(244, 244, 244, 1);

    min-height: 1000px;
}
.reg-div-yearly-offer h3 {
    font-size: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #cf4651;
}
input.inputfull {
    width: inherit;
    height: 30px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    border: 1px solid #27272750;
}
.reg-div {
    height: 60px;
    /* margin: 20px; */
    /* padding: 20px; */
}
.reg-div.header {
    display: flex;
    height: 100px;
    text-align: center;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 60px;
    padding-top: 60px;
    align-content: center;
    align-items: center;
}
.reg-div.header img {
    width: 230px;
}
.header-logo {
    /* width: 50%; */
    /* float: left; */
    margin-right: 50%;
}
.label.total {
    width: 50%  !important;
}
.total {
    margin-top: 25px;
    font-size: 23px;
    color: #000 !important;
    
    justify-content: space-evenly !important;
}
.reg-div.cards {
    display: flex;
    margin-top: 40px;
    padding: 0 100px;
    height: auto;
    /* min-height: 300px; */
    margin-bottom: 30px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.plan-title {
    text-align: center;
    /* color: white !important; */
    height: 20%;
    background-color: cadetblue;
    /* font-size: 32px !important; */
    padding: 10px 0 10px 0;
}
.plan-title .plan-label-app {
    font-size: 24px !important;
    text-transform: uppercase;
}
.plan-prices {
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
}
.plan-title label {
    font-size: 2em !important;
    text-transform: capitalize !important;
    height: 50px !important;
}
.reg-card {
    background-color: white;
    height: 325px;
    padding-top: 10px;
    box-shadow: 0px 0px 10px #27272730;
    display: flex;
    flex-direction: column;
    border: 1px solid #27272750;
}
.reg-div-yearly-offer h3 {
    font-size: 32px;
}
.reg-card-container {
    height: auto;
    width: calc( 20% - 35px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.reg-div.title {
    text-align: center;
    height: auto;
    color: #272727dd;
}
.reg-div-selector label.SwitchToggle {
    margin-right: 20px;
    margin-left: 20px;
}
.reg-div-selector>span.active {
    color: #16ACEA;
}
.reg-div-selector>span:not(.active) {
    opacity: 0.45;
}
.reg-card-container button {
    width: 65%;
    margin: 40px auto 5px auto;
    font-family: system-ui,sans-serif !important;
    /* margin-right: 18px; */
    border-radius: -4px !important;
}
input.noBorder {
    border: none;
}
.reg-div-selector {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}
.reg-div.title h5 {
    font-size: 46px;
    font-family: "Alata",sans-serif !important;
    font-weight: 500;
    margin-bottom: auto;
    opacity: 0.75;
}
.reg-div.title h3 {
    font-size: 36px;
    font-family: "Alata",sans-serif !important;
    font-weight: 500;
    /* margin-bottom: auto; */
    opacity: 0.75;
}

.registration-section.subscription-data {
    background-color: white;
    /* padding: 20px; */
}
.perUserCost {
    font-family: sans-serif !important;
    font-size: 35px;
    font-weight: 600;
}
.discount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
}
.perUserCost span {
    font-size: 14px;
    opacity: 0.8;
}
.registration-section.subscription-data {
    background-color: white;
    padding: 60px 160px;
    display: flex;
    justify-content: space-around;
}
.subscriptionformField fieldset {
    max-height: 40px;
    /* line-height: 23px; */
}

label.subscription-form.label {
    margin: 10px 20px;
    color: #272727cd;
    /* width: 30%; */
    display: block;
    justify-content: flex-end;
    align-items: center;
}
.plan-prices div:not(.perUserCost) {
    font-size: 12px;
    text-transform: capitalize;
    opacity: 1;
    color: black;
}
.subscriptionCountries {
    width: 50%;
}
label.subscription-form.label input[type="text"], label.subscription-form.label input[type="month"] {
    margin-left: 30px;
    width: 55%;
    border: 1px solid #27272750;
    border-radius: 3px;
}
.subscription-form a {
    text-decoration: underline;
    display: contents;
}
.registration-section.footer {
    /* align-items: flex-end; */
    display: flex;
    /* height: 400px; */
    align-items: flex-end;
    justify-content: center;
}
.subscription button {
    width: 80%;
    text-align: center;
    max-width: 400px;
    /* display: block !important; */
    /* float: right; */
    margin-top: 30px;
    margin-bottom: -20px;
}

@media (min-width:1050px) {
    .reg-div.cards {
        min-width: 1200px;
    }
    .registration-section.subscription-data {
        width: 1000px;
        
    }
}
@media (max-width:1050px) {
    .reg-div.cards {
        display: flex;
        padding: 0 40px;
        height: auto;
        /* min-height: 300px; */
        margin-bottom: 30px;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .reg-card-container {
        height: auto;
        width: 220px;
        margin: 40px 0px;
        /* width: calc( 20% - 35px); */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .reg-div.header {
        display: flex;
        height: 100px;
        text-align: center;
        max-width: 250px;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 60px;
        padding-top: 60px;
        align-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
    }
    .registration-section.subscription-data {
        background-color: white;
        padding: 60px 30px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
    }   
    .subscription.column {
        display: flex;
        width: calc( 100% - 20px );
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }
   
}