.cell-info{
    display: flex;
    
}
.cell-info span{
    margin: 0 20px 0 35px;
}
.cell-info span:nth-of-type(1){
    width: 65px;
}
.cell-info.licenses {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin: 10px 0;
    font-size: 19px;
}
.cell-info.licenses span:nth-of-type(1) {
    width: 135px;
}
.cell-info.licenses span:nth-of-type(2) {
    opacity: 0.75;
}

.historyRow {
    width: 100%;
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
}
.historyRow.headers {
    border-bottom: 2px solid #27272727;
}
.historyRow {
    border-bottom: 1px solid #27272727;
}
.historyColumn {
    width: calc(26% - 10px);
}
.historyRow:not(.headers) {
    opacity: 0.7;
}
.plan-warning {
    color: #9f1822;
    background-color: #9f182205;
    padding: 20px;
    border: 0.5px solid #9f182222;
}
.reg-div.cards.profile {
    min-width: 800px;
}
.reg-div.cards.profile {
    display: flex;
    margin-top: 40px;
    padding: 0 0px;
    height: auto;
    /* min-height: 300px; */
    margin-bottom: 30px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.reg-card-container.current,.reg-card-container.disabled {
    opacity: 0.7;
    filter: grayscale(1);
    /* mix-blend-mode: saturation; */
}