.preview-head {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #231E60 !important;
    z-index: 100 !important;
    margin-bottom: 20px !important;
}

.tab-select-page-mobile {
    padding: 23px 0px 0px 0px !important;
}

.preview-image-div {
    height: 208px !important;
    background-color: #FFF !important;
    border-radius: 5px !important;
    margin: 20px 0 !important;
}

.preview-image {
    width: 100% !important;
    height: 100% !important;
    border-radius: 5px !important;
    background-size: cover !important;
    background-position: center !important;
}

.preview-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 37px !important;
    line-height: 35px !important;
    color: #54595E !important;
    margin: 20px 0 !important;
}

.link-preview {
    color: #5AB6DF !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 23px !important;
    text-decoration: none !important;
}

.text-preview {
    color: #6C757D !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 23px !important;
    text-decoration: none !important;
    padding-bottom: 20px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.phone-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.phone-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.carousel-indicators {
    top: 180px !important;
}

.carousel-indicators button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
    background-color: rgba(171, 164, 208, 0.3) !important;
}

.carousel-indicators .active {
    background-color: #ABA4D0 !important
}

.label-form-preview {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 10.8936px !important;
    line-height: 15px !important;
    color: #ABB5BE !important;
}

.preview-user-name-form {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #68717A !important;
}

.preview-job-user-form {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #5AB6DF !important;
}

.preview-profile-user-form {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15.8856px !important;
    line-height: 165% !important;
    color: #6C757D !important;
}

.menu-preview .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    position: absolute;
    box-shadow: none !important;
}

.menu-preview {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 115% !important;
    display: flex !important;
    align-items: center !important;
    color: #3D0A91 !important;
    margin-top: 40px !important;
}

.page-name-preview {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 17px !important;
    line-height: 17px !important;
    color: #8976B6 !important;
    margin-top: 25px !important;
    cursor: pointer;
}

.selected-idiom {
    color: #ABB5BE !important;
}

.carousel .slider-wrapper .slider:before {
    background-color: transparent !important;
}

.carousel .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF !important;
}

.carousel .control-dots {
    bottom: -10px !important;
}

.carousel .control-dots .dot {
    background: #5AB6DF;
}

.carousel .slider {
    height: 100%;
    max-height: 325px;
}

.text iframe, .text img {
    max-width: 264px;
    max-height: 250px;
}

.text h1, h2, h3, p {
    margin: 0;
}

.text p {
    min-height: 24px;
}